@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Implement override or variables to be used globally  */
  }

  .dark {
    /* implement override for dark mode here */
  }
}

@layer base {
  * {
    box-sizing: border-box;
  }
  html {
    font-family: 'Open Sans', sans-serif;
    font-size: var(--ui-font-size, 16px);
    letter-spacing: 0;
    line-height: 24px;
    scrollbar-gutter: stable;
  }

  body {
    @apply tw-bg-background tw-text-text;
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    margin: 0;
    font-size: 16px;
    overscroll-behavior: none;
  }

  html body {
    overflow-y: auto !important;
    margin-right: 0px !important;
  }
}

/* TODO - can this not be moved to the heading component? */
@layer components {
  .ui-h1 {
    font-size: 32px;
    line-height: 37px;
  }
  @media screen and (min-width: theme('screens.lg')) {
    .ui-h1 {
      font-size: 44px;
      line-height: 51px;
    }
  }

  .ui-h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
  }
  @media screen and (min-width: theme('screens.lg')) {
    .ui-h2 {
      font-size: 32px;
      line-height: 37px;
    }
  }

  .ui-h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
  }

  .ui-h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  .ui-h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .ui-Breadcrumb_Item {
    @apply tw-hidden tw-flex-nowrap tw-justify-center tw-align-top;
    @apply md:tw-flex md:before:tw-mr-3 md:before:tw-block md:before:tw-h-[22px] md:before:tw-w-6 md:before:tw-flex-shrink-0 md:before:tw-bg-icon-arrow-right md:first:before:tw-hidden;
    @apply max-md:[&:nth-last-child(2)>*]:before:tw-mr-2 max-md:[&:nth-last-child(2)>*]:before:tw-block max-md:[&:nth-last-child(2)>*]:before:tw-h-[22px] max-md:[&:nth-last-child(2)>*]:before:tw-w-6 max-md:[&:nth-last-child(2)>*]:before:tw-bg-icon-arrow-left;
    @apply [&:not(:last-child)]:tw-flex-shrink-0 [&:nth-last-child(2)]:tw-flex;
    @apply tw-text-grey40 [&:not(:last-child)]:tw-text-blue30;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .ui-no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .ui-no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ui-no-scroll,
  .ui-no-scroll
    *:not(.ui-stop-no-scroll *):not(.ui-stop-no-scroll):has(
      .tw-overflow-auto,
      .tw-overflow-scroll,
      .tw-overflow-y-auto,
      .tw-overflow-y-scroll
    ) {
    overflow: clip !important;
  }

  :root {
    --card-clip-size: 45px;
  }
  @media (min-width: 640px) {
    :root {
      --card-clip-size: 65px;
    }
  }
  @media (min-width: 1024px) {
    :root {
      --card-clip-size: 85px;
    }
  }

  :nth-last-child(1 of div.bar-bottom-border) {
    @apply tw-border-b tw-border-b-grey70;
  }

  .ui-promo-image-clip-path {
    clip-path: polygon(300px 0%, 100% 00%, 100% 100%, 0% 100%);
  }
}
